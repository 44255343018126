<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner auth-bg m-0 justify-content-center">
      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">My Munshi</h2>
      </b-link> -->
      <!-- /Brand logo-->

      <b-col
        class="d-flex justify-content-center auth-bg p-5"
        align-self="center"
      >
        <div>
          <b-col lg="12" class="px-xl-2 mx-auto">
            <b-card>
              <p class="text-center">
                <b-img
                  height="100px"
                  :src="require('@/assets/images/logo/logo_light.png')"
                />
              </p>
              <b-card-title class="mb-1 text-center">
                Verify OTP! ✅
              </b-card-title>
              <b-card-text class="mb-2 text-center">
                OTP has been sent to your mobile +91xxxxxxxx{{
                  $store.state.resetPasswordStore.mobile.toString().slice(-2)
                }}, enter below to verify your account.
              </b-card-text>

              <!-- form -->
              <validation-observer ref="simpleRules">
                <b-form
                  class="auth-forgot-password-form mt-2"
                  @submit.prevent="validationForm"
                >
                  <b-form-group label="OTP" label-for="forgot-password-email">
                    <validation-provider
                      #default="{ errors }"
                      name="OTP"
                      rules="required"
                    >
                      <b-form-input
                        id="forgot-password-otp"
                        v-model="otp"
                        :state="errors.length > 0 ? false : null"
                        name="forgot-password-otp"
                        placeholder="Enter OTP"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-overlay
                    :show="$store.state.resetPasswordStore.statusLoading"
                    rounded="sm"
                    variant="transparent"
                    opacity="0.5"
                    blur="2px"
                  >
                    <b-button type="submit" variant="primary" block>
                      Verify OTP
                    </b-button>
                  </b-overlay>
                </b-form>
              </validation-observer>

              <p class="text-center mt-2">
                <b-link @click="$router.go(-1)">
                  <feather-icon icon="ChevronLeftIcon" /> Change Account?
                </b-link>
              </p>
            </b-card>
          </b-col>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BCard,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BOverlay
  },
  data() {
    return {
      otp: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.otp.length === 6) {
            let payload = {
              otp: this.otp
            }
            this.$store
              .dispatch('resetPasswordStore/verifyOTP', { ctx: this, payload })
              .then((status) => {
                if (status) {
                  this.$router.replace({
                    name: 'auth-forgot-password'
                  })
                }
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Enter 6 digit OTP.',
                icon: 'EditIcon',
                variant: 'error'
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
